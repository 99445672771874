import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const { Paragraph, Text } = Typography;

export const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="Please try again later"
      extra={[
        <Button
          type="primary"
          key="console"
          onClick={() => navigate("/customer")}
        >
          Go Dashboard
        </Button>,
        <Button key="buy">Try Again now</Button>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            The content you submitted has the following error:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          account has been blocked. Contact Admin
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          account does not have enough permissions Go back
        </Paragraph>
      </div>
    </Result>
  );
};
