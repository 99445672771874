import React, { useContext, useEffect, useState } from "react";
import QuickbooksContext from "./QuickbooksContext";
import { ALERT_INITIAL_STATE } from "../utils/data";
import { useRequest } from "../hooks";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import AuthContext from "./AuthContext";
interface Props {
  children: JSX.Element | JSX.Element[];
}

const QuickbooksProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const { handleLoginQuickbooks, onLogout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isSuccess, isError]);

  // Utils

  const handleRefreshQbData = () => {
    setIsLoading(true);
    const token = localStorage.getItem("info");
    window.location.replace(`${process.env.REACT_APP_SERVER}/?token=${token}`);
  };

  const handleGetQbData = (next?: () => void) => {
    const token = localStorage.getItem("info");
    if (token) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      handleRequest({
        endpoint: `quickbooks-crm-data/?token=${token}`,
        options,
        onSuccess: (response) => {
          if (response.success) {
            setIsSuccess({ status: true, message: "Data has been updated" });
            next && next();
          } else {
            setIsSuccess({
              status: true,
              message: "Redirecting to Quickbooks",
            });
            handleLoginQuickbooks();
          }
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          setIsSuccess({
            status: true,
            message: "Redirecting to Quickbooks",
          });
          handleLoginQuickbooks();
        },
      });
    } else {
      setIsError({ status: true, message: "Your Session has expired" });
      onLogout();
    }
  };

  const handleRevokeToken = (next?: () => void) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `revoke-crm`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          setIsSuccess({
            status: true,
            message: "Your Application has been Disconnected to Quickbooks",
          });
          next && next();
        } else {
          setIsError({
            status: true,
            message: "Something went wrong. Please try again later.",
          });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsError({
          status: true,
          message: "Something went wrong. Please try again later.",
        });
        setIsLoading(false);
      },
    });
  };

  // Requests

  return (
    <QuickbooksContext.Provider
      value={{
        qbLoading: isLoading,
        qbSuccess: isSuccess,
        qbError: isError,
        handleRefreshQbData,
        handleGetQbData,
        handleRevokeToken,
      }}
    >
      {children}
    </QuickbooksContext.Provider>
  );
};

export default QuickbooksProvider;
