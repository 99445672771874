import { Card } from "antd";
import React, { CSSProperties, ReactNode } from "react";

export const CardTitle = ({
  title,
  extra,
  loading,
  style,
}: {
  title: string;
  extra?: ReactNode;
  loading?: boolean;
  style?: CSSProperties;
}) => {
  return (
    <Card
      title={<div className="card-title__title">{title}</div>}
      size="small"
      className="card-title"
      extra={extra}
      bodyStyle={{ display: "none" }}
      loading={loading}
    ></Card>
  );
};
