import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFundPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="warning"
      title="This page does not exist"
      extra={
        <Button type="primary" onClick={() => navigate("/login")}>
          Back Home
        </Button>
      }
    />
  );
};
