import { Button, Input } from "antd";
import React, { CSSProperties, useContext } from "react";
import { SearchOutlined } from "@ant-design/icons";
import DataContext from "../../context/DataContext";

const { Search } = Input;

export const SearchInput = ({
  onSearch,
  style,
}: {
  onSearch: (value: string) => void;
  style?: CSSProperties;
}) => {
  const { searchQuery, onChangeSearchQuery } = useContext(DataContext);

  return (
    <Search
      placeholder="Search"
      allowClear
      style={style}
      value={searchQuery}
      onChange={(e) => onChangeSearchQuery(e.target.value)}
      size="middle"
      onSearch={onSearch}
      enterButton={
        <Button
          type="default"
          icon={<SearchOutlined />}
          disabled={!searchQuery}
          style={{ cursor: !searchQuery ? "not-allowed" : "pointer" }}
        ></Button>
      }
    />
  );
};
