import React from "react";
import {
  AdminClient,
  Login,
  Settings,
  HelpPage,
  AdminPage,
  TermsOfService,
  NotFundPage,
  PrivacyPolicy,
  CustomerPage,
  CustomerClient,
  CustomerDetail,
  ActivityClient,
  ReportPage,
  ReportClient,
  QuickbooksSettingsClient,
  QuickbooksSettingsPage,
} from "../../pages";

const routes = {
  initial: "/",
  login: "/login",
  quickbooksPage: "/quickbooks",
  quickbooks: "/quickbooks/:idClient",
  customer: "/customer",
  customerClient: "/customer/:idClient",
  customerDetail: "/customer/:idClient/:idCustomer",
  admin: "/admin",
  activity: "/activity/:idClient",
  adminClient: "/admin/:idClient",
  settings: "/settings/:idClient",
  help: "/help",
  terms: "/terms",
  privacy: "/privacy",
  report: "/report",
  reportClient: "/report/:idClient",
  notFound: "/*",
};

export const AppPages = [
  { path: routes.login, protected: false, component: <Login /> },
  { path: routes.terms, protected: false, component: <TermsOfService /> },
  { path: routes.terms, protected: true, component: <TermsOfService /> },
  { path: routes.privacy, protected: false, component: <PrivacyPolicy /> },
  { path: routes.privacy, protected: true, component: <PrivacyPolicy /> },
  { path: routes.admin, protected: true, component: <AdminPage /> },
  { path: routes.adminClient, protected: true, component: <AdminClient /> },
  { path: routes.customer, protected: true, component: <CustomerPage /> },
  { path: routes.initial, protected: true, component: <CustomerPage /> },
  {
    path: routes.customerClient,
    protected: true,
    component: <CustomerClient />,
  },
  {
    path: routes.customerDetail,
    protected: true,
    component: <CustomerDetail />,
  },
  {
    path: routes.quickbooks,
    protected: true,
    component: <QuickbooksSettingsClient />,
  },
  {
    path: routes.quickbooksPage,
    protected: true,
    component: <QuickbooksSettingsPage />,
  },
  { path: routes.activity, protected: true, component: <ActivityClient /> },
  { path: routes.settings, protected: true, component: <Settings /> },
  { path: routes.help, protected: true, component: <HelpPage /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  { path: routes.reportClient, protected: true, component: <ReportClient /> },
  { path: routes.notFound, protected: true, component: <NotFundPage /> },
  { path: routes.notFound, protected: false, component: <NotFundPage /> },
];
