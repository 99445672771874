import React from "react";
import { Row } from "antd";
import { CardTitle, ColumnComponent } from "../../components/ui";

export const Settings = () => {
  return (
    <Row gutter={[24, 0]} align="top" justify="center">
      <ColumnComponent>
        <CardTitle title="Settings" />
      </ColumnComponent>
    </Row>
  );
};
