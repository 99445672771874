import React, { useContext, useEffect, useState } from "react";
import { LoginForm } from "../../components/login";
import { useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Row } from "antd";
import { ColumnComponent } from "../../components/ui";
import DataContext from "../../context/DataContext";
import { Base64 } from "js-base64";

export const Login = () => {
  const [searchParams] = useSearchParams();
  const { validateExternalSession, handleWarning } = useContext(AuthContext);

  useEffect(() => {
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        const messageDecoded = Base64.decode(message);
        handleWarning(true, messageDecoded);
      }
    }
    if (searchParams.get("token")) {
      const token = searchParams.get("token");
      token && validateExternalSession(token);
    }
  }, [searchParams]);

  return (
    <Row align="middle" justify="center">
      <ColumnComponent lg={14} md={14} xl={14}>
        <LoginForm />
      </ColumnComponent>
    </Row>
  );
};
