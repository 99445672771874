import React, { useContext } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  TeamOutlined,
  SettingOutlined,
  LogoutOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { onLogout, clientInfo } = useContext(AuthContext);

  const menuItems: MenuProps["items"] = [
    {
      label: "Customers",
      key: `/customer/${clientInfo?._id.toString()}`,
      icon: <TeamOutlined className="icon" />,
    },
    {
      label: "Reports",
      key: `/report/${clientInfo?._id.toString()}`,
      icon: <FileDoneOutlined className="icon" />,
    },
    { type: "divider" },
    {
      label: "Settings",
      key: `/settings/${clientInfo?._id.toString()}`,
      icon: <SettingOutlined className="icon" />,
      children: [
        {
          label: "Activity",
          key: `/activity/${clientInfo?._id.toString()}`,
        },
        {
          label: "Help",
          key: "/help",
        },
        {
          label: "Quickbooks",
          key: `/quickbooks/${clientInfo?._id.toString()}`,
        },
      ],
    },

    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      onLogout(() => navigate("/login"));
    } else {
      navigate(key);
    }
  };

  return (
    <>
      <Menu
        className="pt-2"
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        mode="vertical"
        items={menuItems}
      />
    </>
  );
};

export default Navbar;
