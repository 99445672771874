import { Row } from "antd";
import React from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";

export const ReportPage = () => {
  return (
    <Row gutter={[24, 0]}>
      <ColumnComponent>
        <CardTitle title="Reports" />
      </ColumnComponent>
    </Row>
  );
};
