import React from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { Row } from "antd";

export const AdminClient = () => {
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Settings" />
        </ColumnComponent>
      </Row>
    </>
  );
};
