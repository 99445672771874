import React, { useContext } from "react";
import { ProtectedLayout } from "./ProtectedLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const ProtectedRoute = ({ children }: Props) => {
  const { validateToken, onLogout } = useContext(AuthContext);
  if (!validateToken()) {
    onLogout();
    return <Navigate to="/login" replace />;
  }

  return <ProtectedLayout>{children}</ProtectedLayout>;
};
