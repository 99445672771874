import React from "react";
import {
  ActivityProps,
  CustomerDetailProps,
  CustomerProps,
  FilterProps,
} from "../interfaces/interfaces";
import { CUSTOMER_INFO_INITIAL_STATE } from "../utils/data";

interface Props {
  dataLoading: boolean;
  dataSuccess: { status: boolean; message: string };
  dataError: { status: boolean; message: string };
  dataWarning: { status: boolean; message: string };
  customerList: CustomerProps[];
  activityList: ActivityProps[];
  customerInfo: CustomerDetailProps;
  searchQuery: string;
  client: null | {
    name: string;
    _id: string;
    quickbooksStatus: { connected: boolean };
  };
  onChangeSearchQuery: (value: string) => void;
  handleWarning: (status: boolean, message: string) => void;
  handleSuccess: (status: boolean, message: string) => void;
  getData: (
    endpoint: "crm" | "crm-detail" | "activity" | "client",
    idClient: string,
    idCustomer?: string,
    search?: string,
    dates?: [string, string] | null
  ) => void;
  getReportData: (
    endpoint: "report-crm" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => void;
  handleCreateData: (
    endpoint: "crm" | "activity",
    idClient: string,
    data: any,
    next?: () => void
  ) => void;
  handleEditData: (
    endpoint: "crm" | "activity",
    idClient: string,
    data: any,
    next?: () => void
  ) => void;
  handleBulkEdit: (
    source: "crm",
    idClient: string,
    items: string[],
    newValue: any,
    next: () => void
  ) => void;
  handleDeleteData: (
    endpoint: "crm" | "activity",
    idClient: string,
    id: string
  ) => void;
  handleBulkDeleteData: (
    endpoint: "crm" | "activity",
    idClient: string,
    idList?: string[]
  ) => void;
}

const DataContext = React.createContext<Props>({
  dataLoading: false,
  dataSuccess: { status: false, message: "" },
  dataError: { status: false, message: "" },
  dataWarning: { status: false, message: "" },
  customerList: [],
  activityList: [],
  customerInfo: CUSTOMER_INFO_INITIAL_STATE,
  searchQuery: "",
  client: null,
  onChangeSearchQuery: () => {},
  handleWarning: () => {},
  handleSuccess: () => {},
  getData: () => {},
  getReportData: () => {},
  handleCreateData: () => {},
  handleEditData: () => {},
  handleBulkEdit: () => {},
  handleDeleteData: () => {},
  handleBulkDeleteData: () => {},
});

export default DataContext;
