import { Button, Card, Form, Modal, Popconfirm, Row, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import DataContext from "../../context/DataContext";
import { ActivityForm } from "../../components/admin";
import { ActivityProps } from "../../interfaces/interfaces";
import { CardTitle, ColumnComponent } from "../../components/ui";

export const ActivityClient = () => {
  const { idClient } = useParams();
  const [form] = Form.useForm();
  const [itemToEdit, setItemToEdit] = useState<ActivityProps | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getData, handleDeleteData, dataLoading, activityList } =
    useContext(DataContext);

  useEffect(() => {
    if (idClient) {
      getData("activity", idClient);
    }
  }, [idClient]);

  const columns: ColumnsType<ActivityProps> = [
    {
      title: "Label",
      dataIndex: "Label",
      render: (_, row) => <a>{row.Label}</a>,
      onCell: (record) => {
        return {
          onClick: () => {
            setItemToEdit(record);
            setIsModalOpen(true);
          },
        };
      },
      sorter: {
        compare: (a, b) => a.Label.localeCompare(b.Label),
        multiple: 1,
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      sorter: {
        compare: (a, b) => a.Description.localeCompare(b.Description),
        multiple: 2,
      },
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() =>
              record._id &&
              idClient &&
              handleDeleteData("activity", idClient, record._id)
            }
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Activity" />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            bordered
            hoverable
            className="card overflow_x"
            title="Activity Type List"
            extra={
              <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Add Activity Type
              </Button>
            }
          >
            <Table
              bordered
              loading={dataLoading}
              columns={columns}
              dataSource={activityList}
              size="small"
              rowKey={"_id"}
              tableLayout="auto"
            />
          </Card>
          <></>
        </ColumnComponent>
      </Row>
      <Modal
        title={itemToEdit ? "Edit" : "Create"}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          setItemToEdit(null);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setItemToEdit(null);
        }}
        footer={null}
        centered
        width={300}
      >
        <ActivityForm
          values={itemToEdit}
          idClient={idClient}
          form={form}
          onOk={() => {
            setIsModalOpen(false);
            setItemToEdit(null);
          }}
        />
      </Modal>
    </>
  );
};
