import { Button, Grid, List, Row, Skeleton } from "antd";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import DataContext from "../../context/DataContext";
import { SolutionOutlined, HistoryOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { ModalFilterReport } from "../../components/report";
import { useParams } from "react-router-dom";
import { FilterProps, UserActionProps } from "../../interfaces/interfaces";
import { FILTERS_INITIAL_STATUS } from "../../utils/data";
import { exportLogs, exportReport } from "../../utils/func/ExportFile";

interface DataType {
  name: string;
  description: string;
  icon: ReactNode;
  endpoint: "summary" | "detail";
  label: string;
  action: () => void | null;
}

export const ReportClient = () => {
  const { idClient } = useParams();
  const { dataLoading, getData, getReportData } = useContext(DataContext);
  const { clientInfo } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  useEffect(() => {
    if (idClient) {
      getData("activity", idClient);
      getData("crm", idClient);
    }
  }, [idClient]);

  const list: DataType[] = [
    {
      name: "Custom Report",
      description:
        "The custom report provides a flexible and customizable way to analyze data based on specific criteria such as Customer, and Date Range. It allows users to generate detailed insights into various aspects of operations, customer interactions, and performance within a specified time frame.",
      icon: <SolutionOutlined />,
      endpoint: "summary",
      label: "Filter",
      action: () => setIsModalOpen(true),
    },

    {
      name: "Audit Log",
      description:
        "The User Activity Report provides an overview of user interactions and actions performed within the platform over a specified time period.",
      icon: <HistoryOutlined />,
      endpoint: "detail",
      label: "Export",
      action: () =>
        clientInfo &&
        getReportData(
          "logs",
          clientInfo._id,
          FILTERS_INITIAL_STATUS,
          (data: UserActionProps[]) => exportLogs(data)
        ),
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " Reports"} />
        </ColumnComponent>
        <ColumnComponent lg={24} xl={24}>
          <List
            loading={dataLoading}
            itemLayout="horizontal"
            dataSource={list}
            bordered
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button type="link" onClick={item.action}>
                    {item.label}
                  </Button>,
                ]}
              >
                <Skeleton avatar title={false} loading={dataLoading} active>
                  <List.Item.Meta
                    avatar={!xs && item.icon}
                    title={item.name}
                    description={!xs && item.description}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </ColumnComponent>
      </Row>
      <ModalFilterReport
        open={isModalOpen}
        onSubmit={(filters: FilterProps) => {
          idClient &&
            getReportData("report-crm", idClient, filters, (data: any) =>
              exportReport(data)
            );
        }}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
