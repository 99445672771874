import React from "react";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ProtectedRoute } from "./layout/routes/ProtectedRoutes";
import { PublicRoute } from "./layout/routes/PublicRoutes";
import { config } from "./utils/theme/config";
import { AppPages } from "./layout/routes/Routes";
import AuthProvider from "./context/AuthProvider";
import QuickbooksProvider from "./context/QuickbooksProvider";
import DataProvider from "./context/DataProvider";
import "./globals.css";
import './assets/sass/main.scss';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <QuickbooksProvider>
        <DataProvider>
          <ConfigProvider {...config}>
            <Routes>
              {AppPages?.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute>{route.component}</ProtectedRoute>
                    ) : (
                      <PublicRoute>{route.component}</PublicRoute>
                    )
                  }
                />
              ))}
            </Routes>
          </ConfigProvider>
        </DataProvider>
      </QuickbooksProvider>
    </AuthProvider>
  );
};

export default App;
