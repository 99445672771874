import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export const QuickbooksSettingsPage = () => {
  const navigate = useNavigate();
  const { onLogout, getClientId } = useContext(AuthContext);
  useEffect(() => {
    const clientId = getClientId();
    if (clientId) {
      navigate(`/quickbooks/${clientId}`);
    } else {
      onLogout();
    }
  }, []);
  return <></>;
};
