import {
  Button,
  Card,
  Checkbox,
  Grid,
  List,
  Modal,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
  Tabs,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  DownloadOutlined,
  ReloadOutlined,
  ExportOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import DataContext from "../../context/DataContext";
import { USDollar } from "../../utils/func/currency";
import QuickbooksContext from "../../context/QuickbooksContext";
import { exportCustomerList } from "../../utils/func/ExportFile";
import {
  CustomerActivityProps,
  CustomerProps,
} from "../../interfaces/interfaces";
import { CUSTOMER_INITIAL_STATE } from "../../utils/data";
import { AddActivityForm } from "../../components/customer";
import { Base64 } from "js-base64";
import { FilterValue } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";

interface Props extends CustomerProps {
  selected?: boolean;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const CustomerClient = () => {
  const { idClient } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleGetQbData, qbLoading } = useContext(QuickbooksContext);
  const { clientInfo, onLogout } = useContext(AuthContext);
  const {
    getData,
    dataLoading,
    customerList,
    handleBulkEdit,
    handleBulkDeleteData,
  } = useContext(DataContext);
  const [customerListSelect, setCustomerListSelect] = useState<Props[]>([
    CUSTOMER_INITIAL_STATE,
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [dataTableSize] = useState<number>(100);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);

  if (searchParams.get("message")) {
    const message = searchParams.get("message")?.toString();
    if (message) {
      const messageDecoded = Base64.decode(message);
      if (messageDecoded === "user must logout") {
        onLogout();
      }
    }
  }

  useEffect(() => {
    if (idClient) {
      getData("crm", idClient);
      getData("activity", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    let newCustomerList = customerList?.map((customer) => {
      return {
        ...customer,
        selected: false,
      };
    });
    setCustomerListSelect(newCustomerList);
  }, [customerList]);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: dataTableSize,
        total: customerList.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [customerList]);

  useEffect(() => {
    setHasSelected(customerListSelect.some((item) => item.selected));
  }, [customerListSelect]);

  const onChange = (checked: boolean, index?: number) => {
    if (index || index === 0) {
      setCustomerListSelect((prev) => {
        return prev.map((item, idx) => {
          return {
            ...item,
            selected: index === idx ? checked : item.selected,
          };
        });
      });
    } else {
      setCustomerListSelect((prev) => {
        return prev.map((item) => {
          return {
            ...item,
            selected: checked,
          };
        });
      });
    }
  };

  const onAddActivityBulk = (CustomerActivity: CustomerActivityProps) => {
    let ids: string[] = [];
    customerListSelect.forEach((item) => {
      if (item.selected) ids.push(item.Id);
    });
    if (ids.length > 0) {
      idClient &&
        handleBulkEdit("crm", idClient, ids, CustomerActivity, () => {
          getData("crm", idClient);
          getData("activity", idClient);
        });
    }
  };

  const getActionsItemList = (item: CustomerProps, index: number) => {
    return [
      <Tooltip title="Add an Activity" placement="bottom">
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => {
            onChange(true, index);
            setIsModalOpen(true);
          }}
        />
      </Tooltip>,
      <Tooltip title="Go to Customer Profile" placement="bottom">
        <Button
          type="link"
          icon={<ExportOutlined />}
          onClick={() => navigate(`/customer/${idClient}/${item._id}`)}
        />
      </Tooltip>,
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() =>
          idClient && handleBulkDeleteData("crm", idClient, [item.Id])
        }
      >
        <Tooltip title="Delete Customer Data" placement="bottom">
          <Button type="link" icon={<DeleteOutlined />}></Button>
        </Tooltip>
      </Popconfirm>,
    ];
  };

  const getActionsMainCard = () => {
    let actions = [
      <Tooltip title="Add Activity" placement="left">
        <Button
          type="link"
          disabled={!hasSelected}
          icon={<UnorderedListOutlined />}
          size="large"
          onClick={() => setIsModalOpen(true)}
        />
      </Tooltip>,
      <Tooltip title={"Export Data"} placement="left">
        <Button
          type="link"
          icon={<DownloadOutlined />}
          size="large"
          onClick={() => exportCustomerList(customerList)}
        />
      </Tooltip>,
    ];
    if (!xs)
      actions.push(
        <SearchInput
          style={{ width: "80%" }}
          onSearch={(value: string) => {
            if (value) {
              idClient && getData("crm", idClient, undefined, value);
            } else {
              idClient && getData("crm", idClient);
            }
          }}
        />
      );

    return actions;
  };

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelectionToEdit = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
  };

  const hasSelectedTable = selectedRowsToEdit.length > 0;

  const columns: ColumnsType<CustomerProps> = [
    {
      title: "Name",
      dataIndex: "MetaData",
      render: (_, { FullyQualifiedName, DisplayName }) => (
        <>{FullyQualifiedName || DisplayName}</>
      ),
      width: 200,
      className: "table-row",
      sorter: {
        compare: (a, b) =>
          a.FullyQualifiedName.localeCompare(b.FullyQualifiedName),
        multiple: 3,
      },
    },

    {
      title: "Phone Number",
      dataIndex: "PrimaryPhone",
      width: 200,
      render: (_, { PrimaryPhone }) => (
        <>{PrimaryPhone?.FreeFormNumber || ""}</>
      ),
      className: "table-row",
    },
    {
      title: "Email Address",
      dataIndex: "PrimaryEmailAddr",
      width: 200,
      render: (_, { PrimaryEmailAddr }) => (
        <>{PrimaryEmailAddr?.Address || ""}</>
      ),
      className: "table-row",
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      className: "align-right table-row",
      width: 200,
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 5,
      },
      render: (_, { Balance }) => (
        <Button type="link">{USDollar.format(Balance) || ""}</Button>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      width: 200,
      render: (_, item, index) => (
        <>
          <Tooltip title="Add an Activity" placement="bottom">
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => {
                onChange(true, index);
                setIsModalOpen(true);
              }}
            />
          </Tooltip>

          <Tooltip title="Go to Customer Profile" placement="bottom">
            <Button
              type="link"
              icon={<ExportOutlined />}
              onClick={() => navigate(`/customer/${idClient}/${item._id}`)}
            />
          </Tooltip>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() =>
              idClient && handleBulkDeleteData("crm", idClient, [item.Id])
            }
          >
            <Tooltip title="Delete Customer Data" placement="bottom">
              <Button type="link" icon={<DeleteOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
        </>
      ),
      className: "table-row",
    },
  ];

  const itemsToRender = [
    {
      label: "Table",
      key: "0",
      children: (
        <Card bordered className="card customer-client__card" title="Customers">
          <Table
            bordered
            columns={columns}
            rowSelection={rowSelectionToEdit}
            dataSource={customerList}
            loading={dataLoading || qbLoading}
            pagination={tableParams.pagination}
            size="small"
            rowKey={"_id"}
            // scroll={{ y: "70vh", x: "100%" }}
            tableLayout="auto"
          />
        </Card>
      ),
    },
    {
      label: "List",
      key: "1",
      children: (
        <Card
          bordered
          className="card customer-client__card"
          title={
            <Checkbox onChange={(event) => onChange(event.target.checked)}>
              Select All
            </Checkbox>
          }
        >
          <List
            pagination={{}}
            loading={dataLoading || qbLoading}
            dataSource={customerListSelect}
            rowKey={"Id"}
            size="small"
            className="customer-client__list"
            renderItem={(item, index) => (
              <List.Item
                className={`customer-client__list__item ${
                  item.selected ? "customer-client__list__item--selected" : ""
                }`}
                actions={xs ? [] : getActionsItemList(item, index)}
              >
                <List.Item.Meta
                  avatar={
                    <Checkbox
                      checked={item.selected}
                      onChange={(event) =>
                        onChange(event.target.checked, index)
                      }
                    ></Checkbox>
                  }
                  title={
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => navigate(`${item._id}`)}
                    >
                      {item.CompanyName || item.DisplayName}
                    </Button>
                  }
                  description={
                    <>
                      {!xs ? (
                        <>
                          {item.BillAddr && (
                            <div>
                              Billing Address: {item.BillAddr?.Line1}{" "}
                              {item.BillAddr?.City}
                            </div>
                          )}
                          {item.PrimaryPhone && (
                            <div>
                              Phone number: {item.PrimaryPhone?.FreeFormNumber}
                            </div>
                          )}
                          <div>
                            Open Balance: {USDollar.format(item.Balance)}
                          </div>
                        </>
                      ) : (
                        getActionsItemList(item, index)
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      ),
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - Customers"}
            extra={
              <Tooltip title="Refresh Quickbooks Data">
                <Button
                  type="link"
                  icon={<ReloadOutlined className="icon--white" />}
                  size="large"
                  onClick={() =>
                    idClient && handleGetQbData(() => getData("crm", idClient))
                  }
                />
              </Tooltip>
            }
          />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            bordered
            bodyStyle={xs ? {} : { display: "none" }}
            actions={getActionsMainCard()}
          >
            <SearchInput
              style={{ width: "80%" }}
              onSearch={(value: string) => {
                if (value) {
                  idClient && getData("crm", idClient, undefined, value);
                } else {
                  idClient && getData("crm", idClient);
                }
              }}
            />
          </Card>
        </ColumnComponent>
        <ColumnComponent>
          <Tabs
            defaultActiveKey="0"
            type="card"
            size="middle"
            items={itemsToRender}
          />
        </ColumnComponent>
      </Row>
      <Modal
        title={"Add Activity"}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
      >
        <AddActivityForm
          onSubmit={(values) => {
            onAddActivityBulk(values);
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
