import React from "react";
import { ALERT_INITIAL_STATE } from "../utils/data";

interface Props {
  isLoggedIn: boolean;
  authSuccess: { status: boolean; message: string };
  authError: { status: boolean; message: string };
  authLoading: boolean;
  clientInfo: null | {
    name: string;
    _id: string;
  };
  userInfo: null | {
    name: string;
    user: string;
    _id: string;
  };
  dataWarning: { status: boolean; message: string };
  handleWarning: (status: boolean, message: string) => void;
  handleSuccess: (status: boolean, message: string) => void;
  onLogin: (username: string, password: string, quickbooks: boolean) => void;
  onLogout: (next?: () => void) => void;
  removeAuthData: () => void;
  validateToken: () => boolean;
  getClientId: () => string | null;
  handleLoginQuickbooks: () => void;
  validateExternalSession: (token: string) => void;
  handleLoginQuickbooksSSO: () => void;
}

const AuthContext = React.createContext<Props>({
  isLoggedIn: false,
  authSuccess: ALERT_INITIAL_STATE,
  authError: ALERT_INITIAL_STATE,
  authLoading: false,
  clientInfo: null,
  userInfo: null,
  dataWarning: { status: false, message: "" },
  handleSuccess: () => {},
  handleWarning: () => {},
  onLogin: () => {},
  onLogout: () => {},
  removeAuthData: () => {},
  validateToken: () => false,
  getClientId: () => null,
  handleLoginQuickbooks: () => {},
  validateExternalSession: () => {},
  handleLoginQuickbooksSSO: () => {},
});

export default AuthContext;
