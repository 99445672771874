import React, { useContext, useEffect } from "react";
import { Card, Popconfirm, Row, Typography } from "antd";
import { CardTitle, ColumnComponent } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import { ButtonQuickbooksConnect } from "../../components/quickbooks";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";

export const QuickbooksSettingsClient = () => {
  const { idClient } = useParams();
  const navigate = useNavigate();
  const { Title, Paragraph, Link } = Typography;
  const { handleRevokeToken, qbLoading } = useContext(QuickbooksContext);
  const { getData, client } = useContext(DataContext);
  const { handleLoginQuickbooks } = useContext(AuthContext);
  useEffect(() => {
    idClient && getData("client", idClient);
  }, [idClient]);

  return (
    <>
      <Row gutter={[24, 0]} align="top">
        <ColumnComponent>
          <CardTitle title="Quickbooks Settings" />
        </ColumnComponent>
      </Row>
      <Row gutter={[24, 0]} align="top">
        {client?.quickbooksStatus.connected ? (
          <>
            <ColumnComponent>
              <Card
                loading={qbLoading}
                hoverable={true}
                title=" Disconnect Your QuickBooks from CRM"
              >
                <Paragraph>
                  If you need to disconnect our app from QuickBooks, please
                  follow the steps below. Disconnecting will revoke our app's
                  access and refresh tokens automatically.
                </Paragraph>
                <Title level={5}>Steps to Disconnect</Title>
                <ol>
                  <li>
                    Disconnect QuickBooks: Click on{" "}
                    <Popconfirm
                      title="Yes, Disconnect"
                      onConfirm={() => {
                        handleRevokeToken(() => navigate(0));
                      }}
                    >
                      <Link>Disconnect from QuickBooks</Link>
                    </Popconfirm>
                  </li>
                  <li>
                    Confirm Disconnection: You will be prompted to confirm that
                    you want to disconnect. Click "Yes, Disconnect" to proceed.
                  </li>
                </ol>
                <Paragraph>
                  By disconnecting, our app will no longer have access to your
                  QuickBooks data, and any syncing will stop immediately.
                </Paragraph>
              </Card>
            </ColumnComponent>
            <ColumnComponent>
              <Card hoverable={true} title="What Happens Next?">
                <ul>
                  <li>
                    Revoked Access: Our app will automatically revoke its access
                    and refresh tokens for your QuickBooks account.
                  </li>
                  <li>
                    Data Synchronization: All data synchronization between our
                    app and QuickBooks will cease.
                  </li>
                  <li>
                    Feature Availability: Certain features of our app that rely
                    on QuickBooks data will no longer be available.
                  </li>
                </ul>
                <Title level={5}>How to Reconnect</Title>
                <Paragraph>
                  If you decide to reconnect our app to QuickBooks, you can do
                  so easily by following these steps:
                </Paragraph>
                <ol>
                  <li>Navigate to CRM login or Quickbooks settings.</li>
                  <li>
                    Connect to QuickBooks: Click on the "Connect to QuickBooks"
                    button.
                  </li>
                  <li>
                    Authorize Access: You will be redirected to QuickBooks to
                    authorize access. Follow the prompts to grant our app the
                    necessary permissions.
                  </li>
                </ol>
                <Paragraph>
                  Once reconnected, our app will resume syncing with your
                  QuickBooks data, and all related features will be reactivated.
                </Paragraph>
              </Card>
            </ColumnComponent>
          </>
        ) : (
          <ColumnComponent>
            <Card
              title="Empower Your Business with a Powerful Customer Relationship Manager"
              hoverable
              loading={qbLoading}
              actions={[
                <ButtonQuickbooksConnect
                  onClick={() => handleLoginQuickbooks()}
                />,
              ]}
            >
              <Paragraph>
                CRM is designed to help organizations drive growth, enhance
                customer satisfaction, and build long-lasting customer loyalty.
                Our comprehensive CRM solution provides all the tools you need
                to manage your customer relationships effectively.
              </Paragraph>
              <Title level={4}>How It Works</Title>
              <ul>
                <li>
                  Connect to QuickBooks: Link your QuickBooks account to CRM
                  with a few simple steps.
                </li>
                <li>
                  Import Customer Data: Automatically import your customer data
                  from QuickBooks.
                </li>
                <li>
                  Manage Relationships: Use our intuitive dashboard to track,
                  manage, and enhance your customer relationships.
                </li>
              </ul>
            </Card>
          </ColumnComponent>
        )}

        <ColumnComponent>
          <Card title="Need Help?" hoverable>
            <Paragraph>
              If you encounter any issues or need assistance with disconnecting
              or reconnecting our app, please contact our support team:
            </Paragraph>
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:info@quattroapps.com">info@quattroapps.com</a>
              </li>
              <li>
                Visit our{" "}
                <a href="https://support.quattroapps.app" target="_blank">
                  Support Page
                </a>{" "}
                for assistance.
              </li>
            </ul>
            <Paragraph>
              We value your feedback! If you have any comments or suggestions on
              how we can improve our services, please let us know.
            </Paragraph>
            <Paragraph>
              Thank you for using our app. We hope to continue serving you!
            </Paragraph>
          </Card>
        </ColumnComponent>
      </Row>
    </>
  );
};
