import React, { useEffect, useState } from "react";
import { useRequest } from "../hooks";
import DataContext from "./DataContext";
import {
  ActivityProps,
  CustomerDetailProps,
  CustomerProps,
  FilterProps,
} from "../interfaces/interfaces";
import { CUSTOMER_INFO_INITIAL_STATE } from "../utils/data";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const DataProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ status: false, message: "" });
  const [isSuccess, setIsSuccess] = useState({ status: false, message: "" });
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [customerInfo, setCustomerInfo] = useState<CustomerDetailProps>(
    CUSTOMER_INFO_INITIAL_STATE
  );
  const [isWarning, setIsWarning] = useState({ status: false, message: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [activityList, setActivityList] = useState<ActivityProps[]>([]);
  const [client, setClient] = useState<null | {
    name: string;
    _id: string;
    quickbooksStatus: { connected: boolean };
  }>(null);

  const handleWarning = (status: boolean, message: string) => {
    if (status) {
      setIsWarning({ status, message });
    } else {
      setIsWarning({ status, message });
    }
  };

  const handleSuccess = (status: boolean, message: string) => {
    setIsSuccess({ status, message });
  };

  const getData = (
    endpoint: "crm" | "crm-detail" | "activity" | "client",
    idClient: string,
    idCustomer?: string,
    search?: string,
    dates?: [string, string] | null
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    let endpointApi = searchEncoded
      ? `${endpoint}/${idClient}?search=${searchEncoded}`
      : idCustomer
      ? `${endpoint}/${idClient}/${idCustomer}`
      : `${endpoint}/${idClient}`;
    if (dates && dates[0] && dates[1]) {
      endpointApi += `?start=${dates[0]}&end=${dates[1]}`;
    }
    handleRequest({
      endpoint: endpointApi,
      options,
      onSuccess: (response) => {
        switch (endpoint) {
          case "crm":
            setCustomerList(response.data);
            break;
          case "crm-detail":
            setCustomerInfo(response.data);
            break;
          case "activity":
            setActivityList(response.data);
            break;
          case "client":
            setClient(response.data);
            break;
          default:
            break;
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };
  const getReportData = (
    endpoint: "report-crm" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/?filters=${JSON.stringify(filters)}`,
      options,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          setIsSuccess({ status: true, message: "Data fetched successfully" });
          next && next(response.data);
        } else {
          setIsError({ status: true, message: "No data" });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };

  const handleCreateData = (
    endpoint: "crm" | "activity",
    idClient: string,
    data: any,
    next?: () => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest({
      endpoint: endpoint + "/" + idClient,
      options,
      onSuccess: () => {
        getData(endpoint, idClient);
        next && next();
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        console.log(e);
      },
    });
  };

  const handleEditData = (
    endpoint: "crm" | "activity",
    idClient: string,
    data: any,
    next?: () => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(data),
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/${data._id}`,
      options,
      onSuccess: () => {
        getData(endpoint, idClient);
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };

  const handleBulkEdit = (
    source: "crm",
    idClient: string,
    items: string[],
    newValue: any,
    next: () => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ idList: items, newValue }),
    };
    handleRequest({
      endpoint: `crm-bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        // getData(source, idClient);
        next();
        setIsSuccess({ status: true, message: "Items Updated" });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
      },
    });
  };

  const handleDeleteData = (
    endpoint: "crm" | "activity",
    idClient: string,
    id: string
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/${id}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Item Deleted" });
        getData(endpoint, idClient);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
      },
    });
  };

  const handleBulkDeleteData = (
    endpoint: "crm" | "activity",
    idClient: string,
    idList?: string[]
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
      body: JSON.stringify({ idList }),
    };
    handleRequest({
      endpoint: `${endpoint}-bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Items Deleted" });
        getData(endpoint, idClient);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
      },
    });
  };

  return (
    <DataContext.Provider
      value={{
        dataLoading: isLoading,
        dataSuccess: isSuccess,
        dataError: isError,
        dataWarning: isWarning,
        customerList,
        activityList,
        customerInfo,
        searchQuery,
        client,
        onChangeSearchQuery: (value: string) => setSearchQuery(value),
        handleWarning,
        handleSuccess,
        getData,
        getReportData,
        handleCreateData,
        handleEditData,
        handleBulkEdit,
        handleDeleteData,
        handleBulkDeleteData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
