import * as XLSX from "xlsx";
import {
  CustomerDetailProps,
  CustomerProps,
  UserActionProps,
} from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { USDollar } from "./currency";

interface ExportableDataProps {
  dataSheet: Object[];
  name: string;
}

export const exportLogs = (actions: UserActionProps[]) => {
  const data = actions.map((action) => {
    return {
      Date:
        action.timestamp &&
        dayjs(action.timestamp).format("MM-DD-YYYY HH:mm:ss"),
      User: action.user?.email || "",
      Action: action.action || "",
    };
  });
  handleExportExcel([{ dataSheet: data, name: "Audit Logs" }]);
};

export const exportCustomerList = (customerList: CustomerProps[]) => {
  const data = customerList.map((customer) => {
    return {
      Name: customer.FullyQualifiedName || "",
      "Billing Address": customer.BillAddr?.Line1 || "",
      City: customer.BillAddr?.City || "",
      Phone: customer.PrimaryPhone?.FreeFormNumber || "",
      Email: customer.PrimaryEmailAddr?.Address || "",
      "Open Balance": USDollar.format(customer.Balance) || 0,
    };
  });
  handleExportExcel([{ dataSheet: data, name: "Customers" }], "Customer List");
};

export const exportCustomerDetails = (customerInfo: CustomerDetailProps) => {
  const dataActivity = customerInfo.customerInfo.CustomerActivity.map(
    (item) => {
      return {
        Date: dayjs(item.date).format("MM-DD-YYYY HH:mm:ss"),
        Type: item.activity.Label || "",
        User: item.user.name || "",
        Comment: item.comment || "",
      };
    }
  );
  const dataTransactions = customerInfo.transactions.map((item) => {
    return {
      Num: item.DocNumber || "",
      Type: item.TransactionType || "",
      Terms: item.SalesTermRef?.name || "",
      Balance: USDollar.format(item.Balance),
      "Total Amount": USDollar.format(item.TotalAmt),
      "Due Date": item.DueDate && dayjs(item.DueDate).format("MM-DD-YYYY"),
    };
  });
  const dataPayments = customerInfo.salesEffort.lastPayments.map((item) => {
    return {
      Date: item.TxnDate && dayjs(item.TxnDate).format("MM-DD-YYYY"),
      "Total Amount": USDollar.format(item.TotalAmt),
      "Unnaplied Amount": USDollar.format(item.UnappliedAmt || 0),
    };
  });
  handleExportExcel(
    [
      { dataSheet: dataActivity, name: "Activity" },
      { dataSheet: dataTransactions, name: "Transactions" },
      { dataSheet: dataPayments, name: "Payments" },
    ],
    "Customer Details"
  );
};

export const exportReport = (customerList: CustomerProps[]) => {
  let data: any[] = [];
  customerList.forEach((customer) => {
    if (customer.CustomerActivity.length > 0) {
      let activities = customer.CustomerActivity.map((item) => {
        return {
          Date: dayjs(item.date).format("MM-DD-YYYY HH:mm:ss"),
          Type: item.activity.Label || "",
          User: item.user.name || "",
          Comment: item.comment || "",
        };
      });
      data.push({ dataSheet: activities, name: customer.FullyQualifiedName });
    }
  });
  if (data.length > 0) {
    handleExportExcel(data, "Report");
  }
};

const handleExportExcel = (
  exportData: ExportableDataProps[],
  fileName?: string
) => {
  try {
    const workbook = XLSX.utils.book_new();
    exportData.forEach((data) => {
      const worksheet1 = XLSX.utils.json_to_sheet(data.dataSheet);
      XLSX.utils.book_append_sheet(workbook, worksheet1, data.name);
    });
    let name = fileName || "file";
    XLSX.writeFile(workbook, `${name}.xlsx`);
  } catch (e) {}
};
