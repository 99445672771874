import { DatePicker } from "antd";
import React from "react";
const { RangePicker } = DatePicker;

interface Props {
  onCancel: () => void;
  onSubmit: (dates: [string, string]) => void;
}

export const DateRangeFilter = ({ onCancel, onSubmit }: Props) => {
  return <RangePicker onChange={(_, date) => onSubmit(date)} />;
};
