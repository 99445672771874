import { ColumnsType } from "antd/es/table";
import {
  CustomerActivityProps,
  TransactionProps,
} from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { Button } from "antd";
import { USDollar } from "../../utils/func/currency";

export const columnsTransactionsQb: ColumnsType<TransactionProps> = [
  {
    title: "Num",
    dataIndex: "DocNumber",
    sorter: {
      compare: (a, b) => a.DocNumber.localeCompare(b.DocNumber),
      multiple: 2,
    },
  },
  {
    title: "Type",
    dataIndex: "TransactionType",
    sorter: {
      compare: (a, b) => a.TransactionType.localeCompare(b.TransactionType),
      multiple: 3,
    },
    responsive: ["lg", "md"],
  },

  {
    title: "Due Date",
    dataIndex: "date",
    render: (_, row) => (
      <>
        {row.DueDate
          ? dayjs(row.DueDate).format("MM/DD/YYYY")
          : row.TxnDate
          ? dayjs(row.TxnDate).format("MM/DD/YYYY")
          : ""}
      </>
    ),
    responsive: ["lg", "md"],
  },
  {
    title: "Balance",
    dataIndex: "Balance",
    className: "align-right",
    sorter: {
      compare: (a, b) => a.Balance - b.Balance,
      multiple: 4,
    },
    render: (_, { Balance }) => (
      <Button type="link">{USDollar.format(Balance) || ""}</Button>
    ),
  },
];

export const columnsPaymentsQb: ColumnsType<TransactionProps> = [
  {
    title: "Date",
    dataIndex: "date",
    render: (_, row) => (
      <>{row.TxnDate && dayjs(row.TxnDate).format("MM/DD/YYYY")}</>
    ),
  },
  {
    title: "Amount",
    dataIndex: "TotalAmt",
    className: "align-right",
    sorter: {
      compare: (a, b) => a.TotalAmt - b.TotalAmt,
      multiple: 4,
    },
    render: (_, { TotalAmt }) => (
      <Button type="link">{USDollar.format(TotalAmt) || ""}</Button>
    ),
  },
  {
    title: "Pending",
    dataIndex: "UnappliedAmt",
    className: "align-right",
    render: (_, { UnappliedAmt }) => (
      <Button type="link">
        {UnappliedAmt ? USDollar.format(UnappliedAmt) : USDollar.format(0)}
      </Button>
    ),
    responsive: ["lg", "md"],
  },
];

export const columnsCustomerActivity: ColumnsType<CustomerActivityProps> = [
  {
    title: "Date",
    dataIndex: "date",
    render: (_, row) => <>{row.date && dayjs(row.date).format("MM/DD/YYYY")}</>,
  },
  {
    title: "Type",
    dataIndex: "activity",
    render: (_, row) => <>{row.activity && row.activity.Label}</>,
  },
  {
    title: "User",
    dataIndex: "user",
    render: (_, row) => <>{row.user && row.user.name}</>,
    responsive: ["lg", "md"],
  },

  {
    title: "Comment",
    dataIndex: "comment",
    responsive: ["lg", "md"],
  },
];
