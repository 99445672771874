import { Modal, Form, Row, Col, Select, DatePicker, Input, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import DataContext from "../../context/DataContext";
import TextArea from "antd/es/input/TextArea";
import { CustomerActivityProps } from "../../interfaces/interfaces";
import { CUSTOMER_ACTIVITY_INITIAL_STATE } from "../../utils/data";
import AuthContext from "../../context/AuthContext";

interface Props {
  onSubmit: (values: CustomerActivityProps) => void;
}

export const AddActivityForm = ({ onSubmit }: Props) => {
  const { userInfo } = useContext(AuthContext);
  const { activityList } = useContext(DataContext);
  const [form] = Form.useForm();
  const [values, setValues] = useState<CustomerActivityProps>(
    CUSTOMER_ACTIVITY_INITIAL_STATE
  );
  useEffect(() => {
    form.setFieldsValue(CUSTOMER_ACTIVITY_INITIAL_STATE);
    setValues(CUSTOMER_ACTIVITY_INITIAL_STATE);
  }, [form]);

  return (
    <Form
      layout="vertical"
      initialValues={values}
      form={form}
      onFinish={() => {
        if (userInfo) {
          onSubmit({
            ...values,
            user: { _id: userInfo?._id, name: userInfo.name },
          });
          setValues(CUSTOMER_ACTIVITY_INITIAL_STATE);
        }
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Type">
            <Select
              value={values.activity}
              onChange={(value) =>
                setValues((prev) => {
                  return {
                    ...prev,
                    activity: value,
                  };
                })
              }
            >
              {activityList.length > 0 ? (
                activityList.map((option) => (
                  <Select.Option value={option._id} key={option._id}>
                    {option.Label}
                  </Select.Option>
                ))
              ) : (
                <Select.Option value="">{""}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Date">
            <DatePicker
              name="Date"
              format={"MM/DD/YYYY"}
              value={values.date && dayjs(values?.date)}
              style={{ width: "100%" }}
              allowClear={false}
              onChange={(date) => {
                date &&
                  setValues((prev) => {
                    return {
                      ...prev,
                      date: date,
                    };
                  });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Comment">
            <TextArea
              rows={4}
              placeholder="Comment"
              value={values?.comment}
              onChange={(event) =>
                setValues((prev) => {
                  return {
                    ...prev,
                    comment: event.target.value,
                  };
                })
              }
            />
          </Form.Item>
        </Col>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
