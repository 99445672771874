import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { Base64 } from "js-base64";
import { isExpired, decodeToken } from "react-jwt";
import { useRequest } from "../hooks";
import { ALERT_INITIAL_STATE } from "../utils/data";
interface Props {
  children: JSX.Element | JSX.Element[];
}

interface TokenProps {
  _id: string;
  name: string;
  user: string;
  role: string;
  client: {
    name: string;
    _id: string;
  };
}
const AuthProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarning, setIsWarning] = useState({ status: false, message: "" });
  const [clientInfo, setClientInfo] = useState<null | {
    name: string;
    _id: string;
  }>(null);
  const [userInfo, setUserInfo] = useState<null | {
    name: string;
    user: string;
    _id: string;
  }>(null);

  useEffect(() => {
    validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isError, isSuccess]);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser) {
        setClientInfo(dataUser.client);
        setUserInfo({
          name: dataUser.name,
          user: dataUser.user,
          _id: dataUser._id,
        });
      }
    }
  }, [localStorage.getItem("info")]);

  const handleWarning = (status: boolean, message: string) => {
    setIsWarning({ status, message });
  };

  const handleSuccess = (status: boolean, message: string) => {
    setIsSuccess({ status, message });
  };

  const logoutHandler = async (next?: () => void) => {
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `logout-crm`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
      },
    });
  };

  const removeAuthData = () => {
    setIsLoading(true);
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    setIsLoading(false);
  };

  const loginHandler = (
    username: string,
    pass: string,
    quickbooks: boolean
  ) => {
    setIsLoading(true);
    try {
      const loop1Pass = Base64.encode(pass);
      const loop2Pass = Base64.encode(loop1Pass);
      const data = {
        user: username.toLowerCase(),
        password: loop2Pass,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
      };
      handleRequest({
        endpoint: "auth-crm",
        options,
        onSuccess: (response) => {
          if (response.data.token) {
            localStorage.setItem("info", response.data.token);
            setIsSuccess({ status: true, message: "" });
            if (quickbooks) {
              handleLoginQuickbooks();
            } else {
              navigate(0);
            }
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const handleLoginQuickbooks = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `auth-uri-crm`,
      options,
      onSuccess: (response) => {
        if (response.data) {
          if (response.data.authUri) {
            window.location.replace(response.data.authUri);
          } else {
            setIsError({ status: true, message: "An error has ocurred" });
          }
        } else {
          setIsError({ status: true, message: "An error has ocurred" });
          logoutHandler();
        }
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "An error has ocurred" });
      },
    });
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler();
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("info");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
    } else {
      return false;
    }
    return true;
  };

  const getClientId = () => {
    let result = null;
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: TokenProps | null = decodeToken(tokenCoded);
      if (dataUser) result = dataUser?.client._id;
    }
    return result;
  };

  const handleLoginQuickbooksSSO = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `auth-crm-sso`,
      options,
      onSuccess: (response) => {
        if (response.data) {
          if (response.data.authUri) {
            window.location.replace(response.data.authUri);
          } else {
            setIsError({ status: true, message: "An error has ocurred" });
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setIsError({ status: true, message: "An error has ocurred" });
          logoutHandler();
        }
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "An error has ocurred" });
      },
    });
  };

  const validateExternalSession = (token: string) => {
    try {
      setIsLoading(true);
      handleRequest({
        endpoint: `external-crm/?authToken=${token}`,
        onSuccess: (response) => {
          if (response.data.token) {
            console.log(response.data);
            localStorage.setItem("info", response.data.token);
            setIsSuccess({ status: true, message: "" });
            // navigate(0);
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
          logoutHandler();
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
      logoutHandler();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        authSuccess: isSuccess,
        authError: isError,
        authLoading: isLoading,
        clientInfo,
        userInfo,
        dataWarning: isWarning,
        handleWarning,
        handleSuccess,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        removeAuthData,
        validateToken,
        getClientId,
        handleLoginQuickbooks,
        validateExternalSession,
        handleLoginQuickbooksSSO,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
