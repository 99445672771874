import React from "react";
import qbButtonMid from "../../assets/img/quickbooks/Sign_in_blue_btn_tall_default.svg";
import qbButtonSmall from "../../assets/img/quickbooks/Sign_in_blue_btn_med_default.svg";
import { Grid } from "antd";

export const ButtonQuickbooks = ({ onClick }: { onClick: () => void }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const qbButton = xs ? qbButtonSmall : qbButtonMid;
  return (
    <img
      style={{ cursor: "pointer" }}
      src={qbButton}
      alt="Quickbooks SSO"
      onClick={onClick}
    />
  );
};
