import {
  Avatar,
  Button,
  Card,
  Row,
  Tooltip,
  Descriptions,
  DescriptionsProps,
  Divider,
  DatePicker,
  Modal,
  Grid,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useNavigate, useParams } from "react-router-dom";
import {
  DownloadOutlined,
  ReloadOutlined,
  PlusOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import DataContext from "../../context/DataContext";
import dayjs from "dayjs";
import {
  CustomerActivityProps,
  LastPaymentProps,
} from "../../interfaces/interfaces";
import Table from "antd/es/table";
import { USDollar } from "../../utils/func/currency";
import QuickbooksContext from "../../context/QuickbooksContext";
import Meta from "antd/es/card/Meta";
import {
  columnsCustomerActivity,
  columnsPaymentsQb,
  columnsTransactionsQb,
} from "./detailColumns";
import { AddActivityForm } from "../../components/customer";
import { exportCustomerDetails } from "../../utils/func/ExportFile";
const { RangePicker } = DatePicker;

const color = "#17549a";

export const CustomerDetail = () => {
  const navigate = useNavigate();
  const { idClient, idCustomer } = useParams();
  const { getData, customerInfo, dataLoading, handleBulkEdit } =
    useContext(DataContext);
  const { handleGetQbData, qbLoading } = useContext(QuickbooksContext);
  const [initials, setInitials] = useState("");
  const [lastPayment, setLastPayment] = useState<LastPaymentProps>({
    date: null,
    value: 0,
  });
  const [items, setItems] = useState<DescriptionsProps["items"]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  useEffect(() => {
    if (idCustomer && idClient) {
      getData("crm-detail", idClient, idCustomer);
      getData("activity", idClient);
    }
  }, [idCustomer, idClient]);

  useEffect(() => {
    if (customerInfo) {
      let newLastPayment = {
        date: customerInfo.salesEffort.lastPayments[0]?.TxnDate,
        value: customerInfo.salesEffort.lastPayments[0]?.TotalAmt,
      };
      if (newLastPayment.date) {
        setLastPayment(newLastPayment);
      } else {
        setLastPayment({
          date: null,
          value: 0,
        });
      }
    }
  }, [customerInfo]);

  useEffect(() => {
    if (customerInfo.customerInfo) {
      const info = customerInfo.customerInfo;
      let initials: string[] = [];
      let words = customerInfo.customerInfo?.FullyQualifiedName?.split(" ");
      if (words) {
        words.forEach((item, index) => {
          if (index <= 2) {
            let firstLetter = item[0];
            initials.push(firstLetter);
          }
        });
      }
      initials.length > 0 && setInitials(initials.join(""));
      let newItems = [];
      newItems.push(
        {
          key: "1",
          label: "Email",
          children: info?.PrimaryEmailAddr?.Address,
          className: "descriptions__label",
        },
        {
          key: "2",
          label: "Billing Address",
          children:
            info?.BillAddr?.Line1 && info?.BillAddr?.City
              ? info?.BillAddr?.Line1 + " " + info?.BillAddr?.City
              : "No Info",
          className: "descriptions__label",
        },
        {
          key: "3",
          label: "Phone number",
          children: info?.PrimaryPhone?.FreeFormNumber || "No Info",
          className: "descriptions__label",
        },
        {
          key: "4",
          label: "Open Balance",
          children: USDollar.format(info?.Balance || 0),
          className: "descriptions__label",
        }
      );
      setItems(newItems);
    }
  }, [customerInfo]);

  const gridStyle: React.CSSProperties = {
    width: xs ? "100%" : "33.33%",
    textAlign: "center",
  };

  const onAddActivity = (CustomerActivity: CustomerActivityProps) => {
    let ids = [customerInfo.customerInfo.Id];
    idClient &&
      handleBulkEdit("crm", idClient, ids, CustomerActivity, () => {
        getData("crm-detail", idClient, idCustomer);
        getData("activity", idClient);
      });
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={
              customerInfo.customerInfo.CompanyName ||
              customerInfo.customerInfo.FullyQualifiedName
            }
            extra={
              <Tooltip title="Refresh Quickbooks Data">
                <Button
                  type="link"
                  icon={<ReloadOutlined style={{ color: "#FFF" }} />}
                  size="large"
                  onClick={() =>
                    idClient &&
                    handleGetQbData(() => {
                      getData("crm-detail", idClient, idCustomer);
                      getData("activity", idClient);
                    })
                  }
                />
              </Tooltip>
            }
          />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            title={""}
            loading={dataLoading || qbLoading}
            bordered
            bodyStyle={{ display: "none" }}
            actions={[
              <Tooltip title="Back" placement="left">
                <Button
                  type="link"
                  icon={<LeftOutlined />}
                  size="large"
                  onClick={() => idClient && navigate(`/customer/${idClient}`)}
                />
              </Tooltip>,
              <Tooltip title={"Export Data"} placement="left">
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={() => exportCustomerDetails(customerInfo)}
                />
              </Tooltip>,
              <RangePicker
                style={{ width: "80%" }}
                onChange={(_, dates) =>
                  idClient &&
                  getData("crm-detail", idClient, idCustomer, undefined, dates)
                }
              />,
            ]}
          ></Card>
        </ColumnComponent>
      </Row>
      <Row align="middle" justify="center">
        <ColumnComponent xl={2} lg={4} md={4}>
          <Avatar
            style={{ backgroundColor: color, verticalAlign: "middle" }}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            gap={4}
          >
            {initials}
          </Avatar>
        </ColumnComponent>
        <ColumnComponent xl={6} lg={20} md={20}>
          <Descriptions
            items={items}
            column={1}
            className="descriptions"
          />
        </ColumnComponent>
        <ColumnComponent xl={16}>
          <Card
            bordered
            title="Sales Effort"
            loading={dataLoading || qbLoading}
          >
            <Card.Grid style={gridStyle}>
              <Meta
                title="Last Payment"
                description={
                  lastPayment.date
                    ? USDollar.format(lastPayment.value) +
                      " at " +
                      dayjs(lastPayment.date).format("MM/DD/YYYY")
                    : "No Info"
                }
              />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Meta
                title="Yearly spending"
                description={USDollar.format(
                  customerInfo.salesEffort.yearlySpending || 0
                )}
              />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Meta
                title="Cost number"
                description={`${
                  customerInfo?.salesEffort?.costNumber[0] || 0
                } Hours ${
                  customerInfo.salesEffort.costNumber[1] > 0
                    ? customerInfo.salesEffort.costNumber[1] + " Minutes"
                    : ""
                } `}
              />
            </Card.Grid>
          </Card>
        </ColumnComponent>
      </Row>
      <Row align="top" justify="center">
        <ColumnComponent>
          <CardTitle
            title="Activity"
            extra={
              <Tooltip title="Add Activity">
                <Button
                  type="link"
                  icon={<PlusOutlined style={{ color: "#FFF" }} />}
                  size="large"
                  onClick={() => setIsModalOpen(true)}
                />
              </Tooltip>
            }
          />
          <Table
            style={{ marginTop: "10px" }}
            loading={dataLoading || qbLoading}
            bordered
            columns={columnsCustomerActivity}
            pagination={{}}
            dataSource={customerInfo.customerInfo.CustomerActivity}
            size="small"
            rowKey="_id"
          />
        </ColumnComponent>
        <ColumnComponent xl={11}>
          <CardTitle title="Open Transactions" />
          <Table
            style={{ marginTop: "10px" }}
            bordered
            loading={dataLoading || qbLoading}
            columns={columnsTransactionsQb}
            pagination={{}}
            dataSource={customerInfo.transactions}
            size="small"
            rowKey="_id"
          />
        </ColumnComponent>
        <ColumnComponent xl={1}>
          <Divider type="vertical" />
        </ColumnComponent>
        <ColumnComponent xl={12}>
          <CardTitle title="Last Payments" />
          <Table
            style={{ marginTop: "10px" }}
            loading={dataLoading || qbLoading}
            bordered
            columns={columnsPaymentsQb}
            pagination={{}}
            dataSource={customerInfo.salesEffort.lastPayments}
            size="small"
            rowKey="_id"
          />
        </ColumnComponent>
      </Row>
      <Modal
        title={"Add Activity"}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
      >
        <AddActivityForm
          onSubmit={(values) => {
            onAddActivity(values);
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
