import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { ACTIVITY_INITIAL_STATE } from "../../utils/data";
import DataContext from "../../context/DataContext";
import { ActivityProps } from "../../interfaces/interfaces";

interface Props {
  form: FormInstance<any>;
  values: ActivityProps | null;
  idClient: string | undefined;
  onOk: () => void;
}

export const ActivityForm = ({ form, values, idClient, onOk }: Props) => {
  const { handleEditData, handleCreateData } = useContext(DataContext);
  const [newValues, setNewValues] = useState<ActivityProps>(
    ACTIVITY_INITIAL_STATE
  );
  useEffect(() => {
    if (values) {
      setNewValues(values);
      form.setFieldsValue(values);
    } else {
      setNewValues(ACTIVITY_INITIAL_STATE);
      form.setFieldsValue(ACTIVITY_INITIAL_STATE);
    }
  }, [form, values]);

  const resetForm = () => {
    setNewValues(ACTIVITY_INITIAL_STATE);
    form.setFieldsValue(ACTIVITY_INITIAL_STATE);
    onOk();
  };

  return (
    <Row gutter={0} justify="center">
      <Col xs={24} md={24}>
        <Form
          layout="vertical"
          initialValues={newValues}
          form={form}
          onFinish={() =>
            values
              ? idClient &&
              handleEditData("activity", idClient, newValues, resetForm)
              : idClient &&
              handleCreateData("activity", idClient, newValues, resetForm)
          }
        >
          <Form.Item
            label="Label"
            name="Label"
            rules={[{ required: true, message: "Label" }]}
            className="activity-form__form-item"
          >
            <Input
              name="Label"
              placeholder="Label"
              value={newValues.Label}
              onChange={(event) =>
                setNewValues((prev) => {
                  return { ...prev, Label: event.target.value };
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="Description"
            rules={[{ required: true, message: "Description" }]}
            className="activity-form__form-item"
          >
            <Input
              name="Description"
              placeholder="Description"
              value={newValues.Description}
              onChange={(event) =>
                setNewValues((prev) => {
                  return { ...prev, Description: event.target.value };
                })
              }
            />
          </Form.Item>

          <Form.Item name="Active" className="activity-form__form-item">
            <Checkbox
              disabled={!values}
              checked={newValues.Active}
              onChange={(event) =>
                setNewValues((prev) => {
                  return { ...prev, Active: event.target.checked };
                })
              }
            >
              Is Active?
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }} className="activity-form__form-item--button">
            <Button type="primary" htmlType="submit" className="activity-form__button">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
