export const Loading = ({specific = false}: { specific?: boolean }) => {
  return (
    <div
      className={`loading-component ${ specific ? 'loading-component--absolute' : ''}`}
  >
    <div className="loading-component__spinner">
      <div className="loading-component__load-animation">
        <div className="loading-component__load-animation__1"></div>
        <div className="loading-component__load-animation__2"></div>
        <div className="loading-component__load-animation__3"></div>
      </div>
    </div>
  </div>
  );
};
